﻿import { hasClass, removeClass, addClass, getElement } from "../../Client/utils/dom";

export function logout() {
    window.location = '/logout';
}

export function initHeader() {
    window.addEventListener("scroll", function () {
        if (window.scrollY === 0) {
            removeClass(getElement("vmxHeader"), "fixed-header");
        } else {
            addClass(getElement("vmxHeader"), "fixed-header");
        }
    })
}

export function clickOutsideMenu() {
    var mainMenu = getElement("mainMenu");
    if (hasClass(mainMenu, "profile-menu-active")) {
        removeClass(mainMenu, "profile-menu-active");
        removeClass(document.body, "overflow-hidden");
    }
}

export function clickOutsideMobileMenu() {
    var mobileMenu = getElement("mobileMenu");
    if (!hasClass(mobileMenu, "hidden")) {
        addClass(mobileMenu, "hidden");
        removeClass(document.body, "overflow-hidden");
    }
}

export function onClickOutsideByElement(event, id, callback) {
    let node = event.target;
    while (1) {
        if (!node) { break; }
        if (node.tagName === "BODY") {
            break;
        }
        else if (node === getElement(id)) {
            return;
        }
        node = node.parentNode;
    }

    callback();
}


export function openMobileSearch(event) {
    event.stopPropagation();
    addClass(getElement("mobileSearchForm"), "popup-active");

    window.addEventListener("click",
        (event) => onClickOutsideByElement(
            event,
            "mobileSearchForm",
            () => removeClass(getElement("mobileSearchForm"), "popup-active")
        )
    );
}

export function onClickDrawer(event) {
    event.stopPropagation();

    addClass(getElement("mainMenu"), "profile-menu-active");
    addClass(document.body, "overflow-hidden");

    window.addEventListener("click",
        (event) => onClickOutsideByElement(
            event,
            "mainMenu",
            clickOutsideMenu
        )
    );
}


export function onClickMobileDrawer(event) {
    event.stopPropagation();

    removeClass(getElement("mobileMenu"), "hidden");
    addClass(document.body, "overflow-hidden");

    window.addEventListener("click",
        (event) => onClickOutsideByElement(
            event,
            "mobileMenu",
            clickOutsideMobileMenu
        )
    );
}

export function openSearch(event) {
    event.stopPropagation();

    addClass(getElement("mainMenu"), "popup-active");

    window.addEventListener("click",
        (event) => onClickOutsideByElement(
            event,
            "mainMenu",
            () => removeClass(getElement("mainMenu"), "popup-active")
        )
    );
}