import Readmore from 'readmore-js';

import { hasClass } from '../../Client/utils/dom';
import { GET } from '../../Client/utils/fetch';

/**
 * Used to play videos in the viewer module
 *
 * @param $refs A references object from AlpineJS.
 */
export function playVideo($refs) {
  if ($refs.video.tagName.toLowerCase() === 'iframe') {
    const height = $refs.video.clientHeight;
    const width = $refs.video.clientWidth;
    $refs.video.src += `?autoplay=1&height=${height}&width=${width}`;

    const { w, h } = calculateRadio(width, height);
    $refs.video.parentNode.parentNode.style = `padding-top: calc(${h} / ${w} * 100%);`;
  } else {
    const height = $refs.video.videoHeight;
    const width = $refs.video.videoWidth;
    const { w, h } = calculateRadio(width, height);

    $refs.video.parentNode.parentNode.style = `padding-top: calc(${h} / ${w} * 100%);`;

    $refs.video.play();
  }
}

/**
 * A recursive GDC algorithm.
 */
const gcd = (a, b) => {
  if (b === 0) {
    return a;
  }

  return gcd(b, a % b);
};

/**
 * Returns the proper radio.
 *
 * @param {number} numerator The numerator value
 * @param {number} denominator The denominator value
 */
function calculateRadio(numerator, denominator) {
  let temp;

  // Takes care of the simple case
  if (numerator === denominator) {
    return { w: 1, h: 1 };
  }

  // Makes sure numerator is always the larger number
  if (numerator < denominator) {
    temp = numerator;
    numerator = denominator;
    denominator = temp;
  }

  const divisor = gcd(numerator, denominator);

  return typeof temp === 'undefined'
    ? {
        w: numerator / divisor,
        h: denominator / divisor,
      }
    : {
        w: denominator / divisor,
        h: numerator / divisor,
      };
}

/**
 * Used to set the corresponding aspect radio base on the image size.
 *
 * @param $event The DOM event
 */
export function setAspectRadioForPreviewContainer($event) {
  const height = $event.target.naturalHeight;
  const width = $event.target.naturalWidth;

  const { w, h } = calculateRadio(width, height);

  const paddingTop = `padding-top: calc(${h} / ${w} * 100%);`;
  $event.target.parentNode.style = paddingTop;

  if (
    $event.target.parentNode.parentNode &&
    hasClass($event.target.parentNode.parentNode, 'js-viewer-preview-container')
  ) {
    $event.target.parentNode.parentNode.style = paddingTop;
  }
}

/**
 * Used to join meeting.
 *
 * @param {string} extensionKey The extension name.
 * @param {string} actionKey The action name.
 * @param {object} queryParams A key value query params.
 */
export function joinMeeting(extensionKey, actionKey, queryParams = {}) {
  if (!extensionKey) {
    throw new Error('The extensionKey parameter is required');
  }

  if (!actionKey) {
    throw new Error('The actionKey parameter is required');
  }

  let url = `/actions/${extensionKey}/${actionKey}`;
  if (Object.keys(queryParams).length) {
    Object.keys(queryParams).forEach((key, index) => {
      url += `${index === 0 ? '?' : '&'}${key}=${queryParams[key]}`;
    });
  }

  GET(url)
    .then(({ data }) => {
      window.open(data.joinUrl, '_blank');
    })
    .catch((error) => console.log(error));
}

/**
 * Creates `ReadMore` functionality.
 *
 * @param className The html element class name.
 */
export function initReadMore(className) {
  const elems = document.getElementsByClassName(className);
  if (!elems.length) {
    return;
  }

  const rmjs = new Readmore(elems, {
    moreLink:
      '<a href="#" class="text-blue-500 opacity-75 font-medium text-normal mt-2">Show More</a>',
    lessLink:
      '<a href="#" class="text-blue-500 opacity-75 font-medium text-normal mt-2">Show Less</a>',
    collapsedHeight: 192,
  });

  SCOverlay.addEvent('afterHide', () => {
    rmjs.destroy();
  });
}
