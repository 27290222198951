export function openProfileEditor() {
  window.SCUtil.loadViewer(null, 'EditProfileViewer');
}

export function toggleState() {
    var country = $("#AddressCountry").val();
    if (country === "USA") {
        $("#AddressState").show();
    }
    else {
        $("#AddressState").hide();
    }
}

export function saveUserProfile() {
  const $saving = $('#formUserProfile input.saving-user-profile');
  const saving = $saving.val();

  const form = $('#formUserProfile').ajaxSubmit({
    beforeSubmit: function () {
      if (saving === 'true') {
        //ignore multiple clicks
        return false;
      }

      const isValid = $('#formUserProfile').valid();
      $saving.val(isValid);
      return isValid;
    },
    url: '/account/update',
    dataType: 'json',
    type: 'POST',
  });

  const xhr = form.data('jqxhr');
  if (xhr) {
    xhr.done(function (result) {
      $saving.val('false');

      if (result) {
        const $div = $('#formUserProfile div.error-message');

        if (result.hasError === true) {
          $div.html(result.message);
          $div.show();
          return;
        }

        SCUtil.toast({ message: 'Profile Updated' });
        window.SCOverlay.hide();
      }
    });
  }
}

function showAvatarCreator(callback) {
  setTimeout(function () {
    window.SCUtil.loadViewer(null, 'AvatarCreatorViewer');

    if (typeof callback === 'function') {
      SCUtil.OffCanvas.closeBtn.onclose = function () {
        callback();
      };
    }
  }, 250);
}

export function openAvatarCreator(hasExistingAvatar, onCloseCallback) {
  if (hasExistingAvatar === true) {
    const response = confirm(
      'Are you sure that you would like to create new avatar?\n\nYour previous picture/avatar will be deleted.'
    );
    if (response === true) {
      showAvatarCreator(onCloseCallback);
    }
  } else {
    showAvatarCreator(onCloseCallback);
  }
}

export function saveNewPassword() {
  const $saving = $('#formChangePassword input.saving-user-profile');
  const saving = $saving.val();
  const form = $('#formChangePassword').ajaxSubmit({
    beforeSubmit: function () {
      if (saving === 'true') {
        //ignore multiple clicks
        return false;
      }

      const isValid = $('#formChangePassword').valid();
      $saving.val(isValid);
      return isValid;
    },
    url: '/account/savenewpassword',
    dataType: 'json',
    type: 'POST',
  });

  const xhr = form.data('jqxhr');
  if (xhr) {
    xhr.done(function (result) {
      $saving.val('false');
      if (result) {
        const $div = $('#formChangePassword div.error-message');
        if (result.hasError === true) {
          $div.html(result.message);
          $div.show();
          return;
        }
        SCUtil.toast({ message: 'Password Changed' });
        window.SCOverlay.hide();
      }
    });
  }
}
