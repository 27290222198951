/**
 * Removes an item from the backpack page.
 *
 * @param {Event} event The click event
 * @param {string} contentId The item content id
 */
export function removeBackpackItem(event, contentId) {
  event.preventDefault();
  event.stopPropagation();

  SCUtil.GET(`/backpack/remove?contentID=${contentId}`).then(() => {
    let $container = $(`div.content-listitem-holder[data-cid=${contentId}]`);
    $container.remove();
  });
}