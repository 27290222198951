import 'photoviewer';

import { loadMore } from '../Extensions/GalleryModule';
import {
  playVideo,
  setAspectRadioForPreviewContainer,
  joinMeeting,
  initReadMore,
} from '../Extensions/ViewerModule';
import { removeBackpackItem } from '../Extensions/ContentListViewer';
import {
  openAvatarCreator,
  saveUserProfile,
  saveNewPassword,
  openProfileEditor,
  toggleState,
} from '../Extensions/EditProfileViewer';
import {
  logout,
  initHeader,
  openSearch,
  openMobileSearch,
  onClickMobileDrawer,
  onClickDrawer,
  clickOutsideMobileMenu,
} from '../Extensions/HeaderModule';

import Forum from '../Extensions/ForumModule';
import { Howl } from '../Extensions/SpacePlayerModule/howler.min';

window.Viewer = {
  playVideo,
  setAspectRadioForPreviewContainer,
  joinMeeting,
  initReadMore,
};

window.GalleryModule = {
  loadMore,
};

window.Header = {
  initHeader,
  openSearch,
  openMobileSearch,
  onClickMobileDrawer,
  onClickDrawer,
  clickOutsideMobileMenu,
};

window.SCUser = {
  logout,
  openProfileEditor,
  saveUserProfile,
  saveNewPassword,
  openAvatarCreator,
  removeBackpackItem,
  toggleState,
};

window.SCForum = Forum;

function closeAllSelect(element) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x,
    y,
    i,
    xl,
    yl,
    arrNo = [];
  x = document.getElementsByClassName('select-items');
  y = document.getElementsByClassName('select-selected');
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (element == y[i]) {
      arrNo.push(i);
    } else {
      y[i].classList.remove('select-arrow-active');
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add('select-hide');
    }
  }
}

/*look for any elements with the class "custom-select":*/
function checkCustomSelects() {
  var x, i, j, l, ll, selElmnt, a, b, c;

  x = document.getElementsByClassName('custom-select');
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName('select')[0];
    ll = selElmnt.length;
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement('DIV');
    a.setAttribute('class', 'select-selected');
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement('DIV');
    b.setAttribute('class', 'select-items select-hide');
    for (j = 1; j < ll; j++) {
      /*for each option in the original select element,
      create a new DIV that will act as an option item:*/
      c = document.createElement('DIV');
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener('click', function (e) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName('select')[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName('same-as-selected');
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute('class');
            }
            this.setAttribute('class', 'same-as-selected');
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener('click', function (e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle('select-hide');
      this.classList.toggle('select-arrow-active');
    });
  }
}

function scrollIntoGroup(section) {
  var element = document.getElementById('group-' + section);
  var offset = 70;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

window.checkCustomSelects = checkCustomSelects;
window.closeAllSelect = closeAllSelect;
window.scrollIntoGroup = scrollIntoGroup;

jQuery(function () {
  // show wait cursor on ajax
  $(document).ajaxStart(function () {
    $('body').css('cursor', 'wait');
  });
  $(document).ajaxComplete(function () {
    $('body').css('cursor', '');
  });

  // jQuery tabs for login
  jQuery('.login-tabset').tabset({
    tabLinks: 'a.login-tab-opener',
    defaultTab: true,
    addToParent: false,
  });

  jQuery('.tab-closer').click(function (e) {
    e.preventDefault();
    jQuery('.tab-content .tab').removeClass('active');
  });

  // wire search textbox
  $('form.search-form input.search-input').on('keypress', function (e) {
    if (e.which == 13) {
      e.preventDefault();
      e.stopPropagation();

      let keyword = $(e.target).val();
      if (keyword && keyword.trim().length > 2) {
        SCUtil.search(keyword);
        return false;
      }
    }
  });
});

/*
 * jQuery Tabs plugin
 */
(function ($, $win) {
  'use strict';

  function Tabset($holder, options) {
    this.$holder = $holder;
    this.options = options;

    this.init();
  }

  Tabset.prototype = {
    init: function () {
      this.$tabLinks = this.$holder.find(this.options.tabLinks);

      this.setStartActiveIndex();
      this.setActiveTab();

      if (this.options.autoHeight) {
        this.$tabHolder = $(
          this.$tabLinks.eq(0).attr(this.options.attrib)
        ).parent();
      }

      this.makeCallback('onInit', this);
    },

    setStartActiveIndex: function () {
      var $classTargets = this.getClassTarget(this.$tabLinks);
      var $activeLink = $classTargets.filter('.' + this.options.activeClass);
      var $hashLink = this.$tabLinks.filter(
        '[' + this.options.attrib + '="' + location.hash + '"]'
      );
      var activeIndex;

      if (this.options.checkHash && $hashLink.length) {
        $activeLink = $hashLink;
      }

      activeIndex = $classTargets.index($activeLink);

      this.activeTabIndex = this.prevTabIndex =
        activeIndex === -1 ? (this.options.defaultTab ? 0 : null) : activeIndex;
    },

    setActiveTab: function () {
      var self = this;

      this.$tabLinks.each(function (i, link) {
        var $link = $(link);
        var $classTarget = self.getClassTarget($link);
        var $tab = $($link.attr(self.options.attrib));

        if (i !== self.activeTabIndex) {
          $classTarget.removeClass(self.options.activeClass);
          $tab
            .addClass(self.options.tabHiddenClass)
            .removeClass(self.options.activeClass);
        } else {
          $classTarget.addClass(self.options.activeClass);
          $tab
            .removeClass(self.options.tabHiddenClass)
            .addClass(self.options.activeClass);
        }

        self.attachTabLink($link, i);
      });
    },

    attachTabLink: function ($link, i) {
      var self = this;

      $link.on(this.options.event + '.tabset', function (e) {
        e.preventDefault();

        if (
          self.activeTabIndex === self.prevTabIndex &&
          self.activeTabIndex !== i
        ) {
          self.activeTabIndex = i;
          self.switchTabs();
        }
        if (self.options.checkHash) {
          location.hash = jQuery(this).attr('href').split('#')[1];
        }
      });
    },

    resizeHolder: function (height) {
      var self = this;

      if (height) {
        this.$tabHolder.height(height);
        setTimeout(function () {
          self.$tabHolder.addClass('transition');
        }, 10);
      } else {
        self.$tabHolder.removeClass('transition').height('');
      }
    },

    switchTabs: function () {
      var self = this;

      var $prevLink = this.$tabLinks.eq(this.prevTabIndex);
      var $nextLink = this.$tabLinks.eq(this.activeTabIndex);

      var $prevTab = this.getTab($prevLink);
      var $nextTab = this.getTab($nextLink);

      $prevTab.removeClass(this.options.activeClass);

      if (self.haveTabHolder()) {
        this.resizeHolder($prevTab.outerHeight());
      }

      setTimeout(
        function () {
          self.getClassTarget($prevLink).removeClass(self.options.activeClass);

          $prevTab.addClass(self.options.tabHiddenClass);
          $nextTab
            .removeClass(self.options.tabHiddenClass)
            .addClass(self.options.activeClass);

          self.getClassTarget($nextLink).addClass(self.options.activeClass);

          if (self.haveTabHolder()) {
            self.resizeHolder($nextTab.outerHeight());

            setTimeout(function () {
              self.resizeHolder();
              self.prevTabIndex = self.activeTabIndex;
              self.makeCallback('onChange', self);
            }, self.options.animSpeed);
          } else {
            self.prevTabIndex = self.activeTabIndex;
          }
        },
        this.options.autoHeight ? this.options.animSpeed : 1
      );
    },

    getClassTarget: function ($link) {
      return this.options.addToParent ? $link.parent() : $link;
    },

    getActiveTab: function () {
      return this.getTab(this.$tabLinks.eq(this.activeTabIndex));
    },

    getTab: function ($link) {
      return $($link.attr(this.options.attrib));
    },

    haveTabHolder: function () {
      return this.$tabHolder && this.$tabHolder.length;
    },

    destroy: function () {
      var self = this;

      this.$tabLinks.off('.tabset').each(function () {
        var $link = $(this);

        self.getClassTarget($link).removeClass(self.options.activeClass);
        $($link.attr(self.options.attrib)).removeClass(
          self.options.activeClass + ' ' + self.options.tabHiddenClass
        );
      });

      this.$holder.removeData('Tabset');
    },

    makeCallback: function (name) {
      if (typeof this.options[name] === 'function') {
        var args = Array.prototype.slice.call(arguments);
        args.shift();
        this.options[name].apply(this, args);
      }
    },
  };

  $.fn.tabset = function (opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    var options = $.extend(
      {
        activeClass: 'active',
        addToParent: false,
        autoHeight: false,
        checkHash: false,
        defaultTab: true,
        animSpeed: 500,
        tabLinks: 'a',
        attrib: 'href',
        event: 'click',
        tabHiddenClass: 'js-tab-hidden',
      },
      opt
    );
    options.autoHeight = options.autoHeight;

    return this.each(function () {
      var $holder = jQuery(this);
      var instance = $holder.data('Tabset');

      if (typeof opt === 'object' || typeof opt === 'undefined') {
        $holder.data('Tabset', new Tabset($holder, options));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
})(jQuery, jQuery(window));
