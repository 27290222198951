/**
 * Loads more items for the gallery module.
 *
 * @param {number} playlistId The playlist id.
 * @param {number} pageId The page id.
 * @param {number} pageModuleId The page module id.
 * @param {number} pageSize The number of items to load.
 * @param {number} nextPage The number of the next page.
 * @param {number} itemCount The current number of items loaded.
 */
export function loadMore(playlistId, pageId, pageModuleId, pageSize, nextPage, itemCount) {
    const $galleryContainer = $("#gallery-" + playlistId)

    // Removes the current "loadMore" button since fetch content will know whether to rerender it again or not. 
    const $loadMore = $("#load-more-" + playlistId)
    $loadMore.remove();

    const url = `/page/${pageId}/module/${pageModuleId}/control/galleryloadmore?pageSize=${pageSize}&pageNumber=${nextPage}&itemCount=${itemCount}`;

    SCUtil.GET(url).then(function (html) {
        $galleryContainer.append(html);
    })
}